<template>
	<Config>
		<template v-slot:page>
			<v-btn v-if="user.role === 'sysAdmin'" icon @click="editNotes">
				<v-icon>mdi-pencil</v-icon>
			</v-btn>
			<v-textarea v-if="editMode" outlined v-model="developmentNotes" label="Notas de Desenvolvimento" />
			<pre v-else align="left">{{ config.developmentNotes }}</pre>
		</template>
	</Config>
</template>
<script>
import Vue from "vue";
import { mapState } from "vuex";

import Config from "../views/Config.vue";

import { editConfig } from "../api/auth";

export default Vue.extend({
	name: "Development",
	components: { Config },
	created() {
		this.developmentNotes = this.config.developmentNotes;
	},
	watch: {
		config: function () {
			this.developmentNotes = this.config.developmentNotes;
		},
	},
	computed: {
		...mapState(["user", "config"]),
	},
	data() {
		return {
			editMode: false,
			developmentNotes: "",
		};
	},
	methods: {
		async editNotes() {
			if (this.editMode) {
				await editConfig({ developmentNotes: this.developmentNotes });

				window.location.reload();
			}

			this.editMode = !this.editMode;
		},
	},
});
</script>
